import { Row } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  NavLink,
  Extra,
  LogoContainer,
} from "./styles";


const Footer = () => {
  return (
    <>
      <Extra>
        <Container border={false}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
          </Row>
          <Row justify="center" align="middle">@{new Date().getFullYear()}</Row>
        </Container>
      </Extra>
    </>
  );
};

export default Footer;
