import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { createRoot } from "react-dom/client";
import Router from "./router";

const root = createRoot(document.getElementById("root")!);

const App = () => (
  <BrowserRouter>
      <Router />
  </BrowserRouter>
);

root.render(<App />);
